import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { library } from "@fortawesome/fontawesome-svg-core"
import { fas } from "@fortawesome/free-solid-svg-icons"
import { fab } from "@fortawesome/free-brands-svg-icons"
import { useTranslation } from "gatsby-plugin-react-i18next"
import { graphql } from "gatsby"

library.add(fas, fab)

export default function Iletisim() {
  const { t } = useTranslation()

  return (
    <Layout>
      <SEO title="İletişim" />
    </Layout>
  )
}

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
